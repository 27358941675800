import React, { useCallback } from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors } from '@/lib/theme'
import { formPrivacy, togglePrivacyAgree } from '../formState'
import { AgreementBase } from './AgreementBase'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'

interface Props {
  appearance?: AppearanceType
}

export const PrivacyAgreement = ({ appearance }: Props) => {
  const { agree } = useSnapshot(formPrivacy)

  const LinkClickHandler = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
  }, [])

  return (
    <AgreementBase isChecked={agree} toggleChecked={togglePrivacyAgree} isPrimary appearance={appearance}>
      <JustText>I agree to the </JustText>
      <LinkStyled href="/privacy/" target="_blank" onClick={LinkClickHandler}>
        Privacy Policy
      </LinkStyled>
    </AgreementBase>
  )
}

const JustText = styled.span``

const LinkStyled = styled.a`
  text-decoration: underline;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${colors.primary.primary};
  }
`
