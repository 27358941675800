import React, { RefObject, useEffect } from 'react'
import { styled } from '@linaria/react'
import { proxy, useSnapshot } from 'valtio'

type AnchorRefType = RefObject<HTMLDivElement>

interface WithCommonProps {
  anchorRef: AnchorRefType
  currentBlockId?: string
}

interface AnchorProps extends WithCommonProps {
  verticalOffset?: number
}

interface Props extends WithCommonProps {
  afterScrollCallback?: () => void
}

export const activeBlockIdState = proxy<{ activeBlockId: string | null }>({ activeBlockId: null })
export const needToScrollState = proxy<{ needToScroll: boolean }>({ needToScroll: false })

export function useScrollTo({ afterScrollCallback, anchorRef, currentBlockId }: Props) {
  const { needToScroll } = useSnapshot(needToScrollState)
  const { activeBlockId } = useSnapshot(activeBlockIdState)

  useEffect(() => {
    if (!anchorRef || !anchorRef.current || !needToScroll || activeBlockId !== currentBlockId) return

    anchorRef.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })

    needToScrollState.needToScroll = false
    if (typeof afterScrollCallback === 'function') afterScrollCallback()
  }, [activeBlockId, afterScrollCallback, anchorRef, currentBlockId, needToScroll])
}

export const Anchor = ({ verticalOffset = 0, anchorRef, currentBlockId }: AnchorProps) => (
  <AnchorView ref={anchorRef} id={currentBlockId} $verticalOffset={verticalOffset} />
)

const AnchorView = styled.span<{ $verticalOffset?: number }>`
  height: 0;
  width: 0;
  position: absolute;
  top: ${({ $verticalOffset = 0 }) => `calc(-1 * (var(--all-top-blocks-h) + ${$verticalOffset}px))`};
`
