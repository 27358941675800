import React from 'react'

interface Props {
  size?: number
  fill?: string
}
export const SearchIcon = ({ size = 16, fill = '#000' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill={fill}>
      <path d="m15.698 14.312-4.046-3.97c.809-1.08 1.293-2.417 1.293-3.87 0-3.574-2.898-6.472-6.472-6.472s-6.473 2.898-6.473 6.472 2.898 6.472 6.473 6.472c1.413 0 2.717-.458 3.781-1.227l4.07 3.994c.392.385 1.022.385 1.4 0 .378-.384.366-1.014-.026-1.399zm-9.225-2.71c-2.834 0-5.131-2.297-5.131-5.13s2.297-5.13 5.131-5.13 5.131 2.297 5.131 5.13c-.001 2.833-2.298 5.13-5.131 5.13z" />
    </svg>
  )
}
