import React from 'react'
import { styled } from '@linaria/react'
import { formFields, fieldInTheForm } from '../formState'
import { Input } from './Input'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'

interface Props {
  appearance: AppearanceType
}

export const InputsGroup = ({ appearance }: Props) => {
  return (
    <Container>
      {fieldInTheForm.map(fieldName => (
        <Input key={fieldName} fieldData={formFields[fieldName]} appearance={appearance} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 24px;
`
