import { proxy } from 'valtio'

export const playerState = proxy<{ currentPlayedId: string | null }>({
  currentPlayedId: null,
})

export const startPlay = (id: string) => {
  playerState.currentPlayedId = id
}

export const stopPlay = () => {
  playerState.currentPlayedId = null
}
