import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'
import { Checkbox } from './Checkbox'

interface Props {
  isChecked: boolean
  toggleChecked: () => void
  children: ReactNode
  appearance?: AppearanceType
  isPrimary?: boolean
}

export const AgreementBase = ({ isChecked, toggleChecked, children, appearance = 'dark', isPrimary = false }: Props) => {
  return (
    <Container>
      <CheckboxStyled isChecked={isChecked} onChange={toggleChecked} appearance={appearance} isPrimary={isPrimary} />
      <Text $isBlack={appearance === 'light'}>{children}</Text>
    </Container>
  )
}

const Container = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`

const CheckboxStyled = styled(Checkbox)`
  margin-right: 12px;
`

const Text = styled.div<{ $isBlack: boolean }>`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: ${({ $isBlack }) => ($isBlack ? colors.textStyles.regular : colors.textStyles.inverseTransparent)};
`
