import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { siteUrl } from '_/config/site-config'
import { Post } from '../../types'
import { colors, desktopSmallMedia, fontLeagueSpartan, fontLibreBaskerville, printMedia } from '@/lib/theme'
import { Button, IconSocial, textLinkStyles } from '@/UI'
import { useBookACall } from '@/hooks'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

interface Props {
  author: Post['authors'][number] & { role?: string }
}

export const AuthorExt = ({ author }: Props) => {
  const { avatar, name, role, bio, slug, twitter, linkedIn } = author
  const bookACall = useBookACall()

  return (
    <Root>
      <ImageBlock>
        <GatsbyImage image={avatar.gatsbyImageData} alt={name} />
      </ImageBlock>
      <Content>
        <Link to={`${siteUrl}/team/${slug}`}>
          <Name>{name}</Name>
        </Link>
        <Role>{role}</Role>
        <Bio>{bio.bio}</Bio>
        <InteractiveContent>
          <ButtonsBlock>
            <Link to={`${siteUrl}/contact/`}>
              <Button preventDefaultClick={false}>Contact</Button>
            </Link>
            <Button onClick={bookACall}>Book a call</Button>
          </ButtonsBlock>
          <SocialBlock>
            {linkedIn && (
              <IconSocial type="LinkedIn" urlAdd={`in/${linkedIn}`} size={24} fillBg={colors.secondary.secondary} />
            )}
            {twitter && <IconSocial type="Twitter" urlAdd={twitter} size={24} fillBg={colors.secondary.secondary} />}
          </SocialBlock>
        </InteractiveContent>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  background-color: ${colors.background.grayLightest};
  color: ${colors.secondary.darkest};
  border-radius: 16px;
  grid-column-gap: 24px;
  overflow: hidden;
  min-height: 288px;

  ${desktopSmallMedia} {
    grid-template-columns: 196px auto;
  }
  
  ${printMedia} {
    min-height: unset;
  }
`

const ImageBlock = styled.div`
  max-width: 196px;
  height: 100%;
  display: none;

  ${desktopSmallMedia} {
    display: flex;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${desktopSmallMedia} {
    padding: 36px 40px 44px 0;
  }
`

const Name = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01em;
  ${textLinkStyles};
`

const Role = styled.span`
  font-family: ${fontLibreBaskerville};
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 163%; /* 26.08px */
  margin-top: 4px;
  opacity: 0.5;
`

const Bio = styled.div`
  font-family: ${fontLibreBaskerville};
  font-size: 14px;
  font-weight: 400;
  line-height: 163%; /* 22.82px */
  margin-top: 16px;
`

const InteractiveContent = styled.div`
  margin-top: 24px;
  display: grid;
  grid-row-gap: 16px;
  align-items: center;
  width: 100%;
  ${doNotPrintThisBlock};

  ${desktopSmallMedia} {
    justify-content: start;
    grid-column-gap: 24px;
    grid-auto-flow: column;
  }
`

const ButtonsBlock = styled.div`
  display: grid;
  grid-row-gap: 10px;

  ${desktopSmallMedia} {
    grid-column-gap: 10px;
    grid-auto-flow: column;
  }
`

const SocialBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-self: start;
`
