import React from 'react'
import { styled } from '@linaria/react'
import { LinkedIn, Twitter, Telegram, Youtube, Mail, Facebook } from './Icons'
import { Styleable } from '@/lib/interfaces'
import { baseUrls } from './baseUrls'

const icons = {
  LinkedIn,
  Twitter,
  Telegram,
  Youtube,
  Mail,
  Facebook,
}

export type IconsSocialType = keyof typeof icons

interface Props extends Styleable {
  type: IconsSocialType
  urlAdd: string
  size?: number
  fillBg?: string
  fillContent?: string
}

export const IconSocial = ({ type, className, urlAdd, size = 48, fillBg, fillContent }: Props) => {
  const urlFinal = `${baseUrls[type]}${urlAdd}`
  const Icon = icons[type]

  return (
    <Root
      $size={size}
      className={className}
      as={urlAdd ? 'a' : 'div'}
      href={urlAdd ? urlFinal : null}
      target={urlAdd ? '_blank' : null}
      rel={urlAdd ? 'noreferer' : null}
    >
      <svg fill="none" height={size} width={size} viewBox="0 0 48 48">
        <Icon fillBg={fillBg} fillContent={fillContent} />
      </svg>
    </Root>
  )
}

const Root = styled.a<{ $size: number }>`
  overflow: hidden;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 6}px;
`
