import { proxy } from 'valtio'

export const modalState = proxy<{ currentModalId: string | null }>({ currentModalId: null })

export const showModal = (id: string) => {
  modalState.currentModalId = id
}

export const hideModal = () => {
  modalState.currentModalId = null
}
