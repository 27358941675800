import React, { ComponentPropsWithoutRef } from 'react'
import { styled } from '@linaria/react'
import { colors, hoverMedia } from '@/lib/theme'

interface Props extends ComponentPropsWithoutRef<'button'> {
  gridArea?: string
  className?: string
  customColor?: string
  onButtonClick?: () => void
}

export const IconButtonCommon = ({ onButtonClick, children, gridArea, customColor, ...rest }: Props) => {
  return (
    <Container onClick={onButtonClick} gridArea={gridArea} customColor={customColor} {...rest}>
      {children}
    </Container>
  )
}

const Container = styled.button<{ gridArea?: string; customColor?: string }>`
  color: ${({ customColor }) => (customColor || colors.white)};
  grid-area: ${({ gridArea }) => gridArea || ''};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  ${hoverMedia} {
    &:hover {
      opacity: 0.65;
    }
  }
`
