import React from 'react'

interface IconAppearance {
  fillBg?: string
  fillContent?: string
}

export const Facebook = ({ fillBg = '#3d5a98', fillContent = '#fff' }: IconAppearance) => (
  <>
    <rect width="48" height="48" fill={fillBg} />
    <path
      d="m33.2005 48.0494v-18.5876h6.2378l.9328-7.2436h-7.1706v-4.6235c0-2.0969.584-3.5285 3.5894-3.5285h3.8367v-6.48927c-1.8579-.19363-3.725-.2857-5.5929-.27579-5.5239 0-9.3282 3.36626-9.3282 9.57566v5.3414h-6.2378v7.2436h6.2378v18.5876z"
      fill={fillContent}
    />
  </>
)

export const Twitter = ({ fillBg = '#000', fillContent = '#fff' }: IconAppearance) => (
  <>
    <rect width="48" height="48" fill={fillBg} />
    <path
      d="m38.689 9-11.121 12.707 13.082 17.293h-10.243l-8.014-10.489-9.182 10.489h-5.09l11.893-13.596-12.546-16.404h10.5l7.253 9.589 8.379-9.589zm-4.05 26.957-18.203-24.075h-3.032l18.407 24.075h2.818z"
      fill={fillContent}
    />
  </>
)

export const Mail = ({ fillBg = '#9e958f', fillContent = '#fff' }: IconAppearance) => (
  <>
    <rect width="48" height="48" fill={fillBg} />
    <path
      d="m35.986 17.962c-.001-.004 0-.009-.001-.013-.046-.833-.38-1.612-.975-2.206-.64-.64-1.491-.993-2.396-.993h-16.725c-.905 0-1.756.353-2.396.993-.595.595-.928 1.374-.974 2.207-.001.003 0 .007-.001.01-.003.061-.018.119-.018.18v11.946c0 .905.353 1.757.993 2.396.64.641 1.491.993 2.396.993h16.724c.905 0 1.756-.353 2.396-.993s.993-1.491.993-2.396v-11.947c.001-.06-.013-.117-.016-.177zm-20.097-1.212h16.724c.371 0 .72.145.982.407.146.146.245.321.314.51l-9.658 6.438-9.658-6.438c.068-.188.168-.364.313-.51.263-.262.612-.407.983-.407zm17.707 14.317c-.263.263-.611.407-.982.407h-16.725c-.371 0-.72-.145-.982-.407-.262-.263-.407-.611-.407-.982v-10.077l9.196 6.131c.168.112.361.168.555.168s.387-.056.555-.168l9.197-6.131v10.077c0 .371-.145.72-.407.982z"
      fill={fillContent}
    />
  </>
)

export const LinkedIn = ({ fillBg = '#007ebb', fillContent = '#fff' }: IconAppearance) => (
  <>
    <rect width="48" height="48" fill={fillBg} />
    <path
      d="m41.333 41.333h-7.123v-12.132c0-3.326-1.264-5.185-3.897-5.185-2.864 0-4.36 1.934-4.36 5.185v12.132h-6.864v-23.111h6.864v3.113s2.064-3.819 6.968-3.819c4.902 0 8.412 2.994 8.412 9.185zm-30.433-26.137c-2.338 0-4.233-1.91-4.233-4.265s1.894-4.264 4.233-4.264 4.232 1.91 4.232 4.265-1.894 4.264-4.232 4.264zm-3.545 26.137h7.158v-23.111h-7.158z"
      fill={fillContent}
    />
  </>
)

export const Telegram = ({ fillBg = '#000', fillContent = '#fff' }: IconAppearance) => (
  <>
    <circle cx="24" cy="24" r="24" fill={fillBg} />
    <path
      d="m35.124 17.011c-.361 3.955-1.924 13.553-2.72 17.984-.336 1.874-.999 2.503-1.64 2.564-1.393.133-2.452-.96-3.801-1.882-2.112-1.442-3.306-2.342-5.356-3.75-2.37-1.627-.834-2.522.517-3.983.353-.383 6.495-6.204 6.613-6.732.015-.067.03-.313-.112-.442-.141-.13-.348-.086-.497-.05-.212.05-3.587 2.375-10.124 6.973-.957.686-1.825 1.02-2.603 1.002-.857-.02-2.505-.504-3.73-.92-1.503-.508-2.698-.778-2.594-1.642.055-.45.65-.912 1.786-1.382 6.996-3.176 11.661-5.27 13.996-6.284 6.665-2.889 8.049-3.39 8.953-3.407.199-.003.643.047.93.29.19.174.313.416.34.677.051.325.065.656.042.984z"
      fill={fillContent}
    />
  </>
)

export const Youtube = ({ fillBg = '#000', fillContent = '#fff' }: IconAppearance) => (
  <>
    <path
      d="M46.997 12.405a6.029 6.029 0 0 0-4.244-4.27C39.011 7.125 24 7.125 24 7.125s-15.01 0-18.753 1.01a6.031 6.031 0 0 0-4.244 4.27C0 16.173 0 24.035 0 24.035s0 7.861 1.003 11.628c.552 2.078 2.178 3.648 4.244 4.202C8.99 40.875 24 40.875 24 40.875s15.01 0 18.753-1.01c2.066-.556 3.692-2.124 4.244-4.202C48 31.895 48 24.035 48 24.035s0-7.862-1.003-11.63z"
      fill={fillBg}
    />
    <path d="M19.09 31.137V16.863l12.546 7.138z" fill={fillContent} />
  </>
)
