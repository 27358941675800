import React from 'react'
import { styled } from '@linaria/react'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { tabletMedia, desktopLargeMedia } from '@/lib/theme'
import { IconSocial } from '@/UI'

export const LightHero = styled.div`
  grid-area: hero;
  padding: 2em;
  display: grid;
  gap: 0.5em;
  grid-template: 'image' 'title' 'excerpt' 'extras' 'social';
  justify-items: center;
  background-color: black;
  //  background-image: radial-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.0)), url(/oooscillate.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  color: white;
  text-shadow: 0 0 50px black;
  font-size: 32px;
  font-weight: 700;
  line-height: ${theme.lineHeights.heading};
  text-align: center;
  max-width: 88rem;

  ${tabletMedia} {
    font-size: 32px;
  }

  ${desktopLargeMedia} {
    font-size: 32px;
  }
`

export const Excerpt = styled.section`
  padding: 0;
  margin: 0;
  grid-area: excerpt;
  color: white;
  text-shadow: 0 0 3px black;
  line-height: ${theme.lineHeights.body};
  max-width: 44em;
  text-align: center;
  & p {
    padding: inherit;
    margin: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`

export const HeroSocial = ({ linkedIn, twitter }) => {
  return (
    <SocialContainer>
      {linkedIn && <IconSocial type="LinkedIn" urlAdd={`in/${linkedIn}`} size={32} fillBg="currentColor" fillContent="#000" />}
      {twitter && <IconSocial type="Twitter" urlAdd={twitter} size={32}  fillBg="currentColor" fillContent="#000" />}
    </SocialContainer>
  )
}

const SocialContainer = styled.aside`
  grid-area: social;
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  & a {
    text-decoration: none;
    transition: 0.3s all;
    color: white;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
  & svg {
    height: 2em;
  }
`
