import type { IconsSocialType } from './IconSocial'

export const baseUrls: Record<IconsSocialType, string> = {
  Twitter: 'https://twitter.com/',
  LinkedIn: 'https://linkedin.com/',
  Mail: 'mailto:?',
  Facebook: 'https://www.facebook.com/',
  Telegram: 'https://t.me/',
  Youtube: 'https://youtube.com/',
}
