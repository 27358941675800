import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Extras, Header, Footer } from './partials'
import { WithChildren } from '@/lib/interfaces'
import {
  SubHeader,
  type SubheaderProps,
  BREADCRUMBS_HEIGHT,
  calculateSubheaderItemsVisibility,
  MediaShowSubheader,
} from '@/blocks/layout/partials/sub-header'
import { desktopLargeMedia, desktopSmallMedia, printMedia, tabletMedia } from '@/lib/theme'

interface Props extends WithChildren, SubheaderProps {
  subheaderItemsHeight?: number
  subheaderItems?: ReactNode
}

export const Layout = ({
  children,
  subheaderItems,
  breadcrumbsText,
  breadcrumbsLink,
  bpToShowSubheaderItems,
  subheaderItemsHeight = 0,
}: Props) => {
  const hasBreadcrumbs = Boolean(breadcrumbsText)
  const hasSubheader = hasBreadcrumbs || subheaderItems
  const subheaderHeight = hasBreadcrumbs ? subheaderItemsHeight + BREADCRUMBS_HEIGHT : subheaderItemsHeight
  const subheaderHeightWithoutItems = hasBreadcrumbs ? BREADCRUMBS_HEIGHT : 0

  return (
    <Wrapper
      $subheaderHeight={subheaderHeight}
      $subheaderHeightWithoutItems={subheaderHeightWithoutItems}
      $bpToShowSubheaderItems={bpToShowSubheaderItems}
    >
      <Header withShadow={!hasSubheader} />
      {hasSubheader && (
        <SubHeader
          breadcrumbsText={breadcrumbsText}
          breadcrumbsLink={breadcrumbsLink}
          bpToShowSubheaderItems={bpToShowSubheaderItems}
        >
          {subheaderItems}
        </SubHeader>
      )}
      <Main>{children}</Main>
      <Footer />
      <Extras />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  $subheaderHeight: number
  $subheaderHeightWithoutItems: number
  $bpToShowSubheaderItems: MediaShowSubheader[]
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--all-top-blocks-h);

  --all-top-blocks-h: calc(
    var(--header-h) +
      ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
        calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'mobile')
          ? $subheaderHeight
          : $subheaderHeightWithoutItems}px
  );

  ${tabletMedia} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'tablet')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }

  ${desktopSmallMedia} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopSmall')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }

  ${desktopLargeMedia} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopLarge')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }
  
  ${printMedia} {
    --all-top-blocks-h: 0;
  }
`

const Main = styled.main`
  width: 100%;
  min-height: 80vh;
  --content-blocks-gap: 48px;

  ${desktopSmallMedia} {
    --content-blocks-gap: 56px;
  }
`
