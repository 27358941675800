import { RefObject, useEffect } from 'react'
import { useIntersectionObserver } from './useIntersectionObserver'
import { activeBlockIdState } from './useScrollTo'

interface Props {
  anchorRef: RefObject<HTMLDivElement>
  isActive: boolean
  currentBlockId: string
}

export const useActiveToc = ({ anchorRef, isActive, currentBlockId }: Props) => {
  const isIntersecting = useIntersectionObserver(anchorRef, { isActive, rootMargin: '-300px 0px' })

  useEffect(() => {
    if (isIntersecting) activeBlockIdState.activeBlockId = currentBlockId
  }, [currentBlockId, isIntersecting])
}
