import React from 'react'
import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import Avatar from './avatar'
import {
  //tabletMedia,
  desktopSmallMedia,
} from '@/lib/theme'
import { theme } from '../gatsby-plugin-theme-ui/index'

const CardGrid = styled.div`
  align-self: start;
  padding: 1em;
  max-width: 72em;
  justify-self: center;
  display: grid;
  grid-auto-flow: dense;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;
  ${desktopSmallMedia} {
    grid-template-columns: repeat(${props => Math.min(4, props.numAuthors)}, 1fr);
  }
`

const OneCard = styled.div`
  border-radius: 0.3em;
  box-shadow: 0 0 3em -1em ${theme.colors.pale};
  background-color: ${theme.colors.pale};
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 0 3em -1em ${theme.colors.deep};
  }
  // for stretched links
  position: relative;
  display: grid;
  gap: 1em;
  padding: 2em;
  &:hover h2 {
    color: ${theme.colors.secondary};
  }
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-template-rows: repeat(auto-fit, minmax(100px, min-content));
`

const CardBody = styled.div`
  grid-column: span 3;
  grid-row: span 2;
  background-color: inherit;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(1.5em, min-content));
  gap: 1em;
`

const name = css`
  padding: 0;
  margin: 0;
  color: black;
  font-size: 1.5em;
  font-weight: 700;
  transition: 0.3s all;
`

const linkedBio = css`
  color: black;
  text-decoration: none;
  font-size: 1em;
  line-height: ${theme.lineHeights.body};
  padding: 0;
  margin: 0;
  & p,
  span {
    font-size: inherit;
    padding: inherit;
    margin: inherit;
    line-height: inherit;
  }
/*  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
  }
*/
`

const Name = ({ children, to }) => {
  return <h2 className={name}><Link to={to}>{children}</Link></h2>
}

const LinkedBio = ({ children, to }) => {
  return <Link className={linkedBio} to={to}>{children}</Link>
}

const SocialContainer = styled.aside`
  // grid-area: social;
  display: flex;
  //width: auto;
  //grid-auto-flow: column;
  gap: 1em;
  & a {
    text-decoration: none;
    transition: 0.3s all;
    color: black;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
  & svg {
    height: 2em;
  }
`

const LinkedIn = ({ handle }) => {
  if (!handle) return null
  return (
    <a href={`https://linkedin.com/in/${handle}`} target="_blank" rel="noreferrer">
      aaa
    </a>
  )
}

const Twitter = ({ handle }) => {
  if (!handle) return null
  return (
    <a href={`https://twitter.com/${handle}`} target="_blank" rel="noreferrer">
      aaa
    </a>
  )
}


export const Social = ({ linkedIn, twitter }) => {
  return (
    <SocialContainer>
      <LinkedIn handle={linkedIn} />
      <Twitter handle={twitter} />
    </SocialContainer>
  )
}


const AuthorGrid = ({ authors, ...props }) => {
  const numAuthors = authors.length

  return (
    <CardGrid numAuthors={numAuthors} {...props}>
      {authors.map(node => {
        return (
          <OneCard key={node.slug}>
            <Avatar image={getImage(node.avatar)} alt={node.name} />
            <CardBody>
              <Name to={`/team/${node.slug}/`}>{node.name}</Name>
              <LinkedBio to={`/team/${node.slug}/`}>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: node.bio.childMarkdownRemark.html,
                  }}
                />
              </LinkedBio>
              <Social linkedIn={node.linkedIn} twitter={node.twitter} />
            </CardBody>
          </OneCard>
        )
      })}
    </CardGrid>
  )
}

export default AuthorGrid
