import React from 'react'
import { useSnapshot } from 'valtio'
import { formNewsletterConsent, toggleNewsletterConsent } from '../formState'
import { AgreementBase } from './AgreementBase'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'

interface Props {
  appearance?: AppearanceType
}

export const NewsletterConsent = ({ appearance }: Props) => {
  const { agree } = useSnapshot(formNewsletterConsent)

  return (
    <AgreementBase isChecked={agree} toggleChecked={toggleNewsletterConsent} appearance={appearance}>
      Subscribe to our newsletter
    </AgreementBase>
  )
}
