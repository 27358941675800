import React, { useRef } from 'react'
import { styled } from '@linaria/react'
import { slugify } from '@/utils/slugify'
import { useScrollTo, Anchor, useActiveToc } from '@/hooks'

interface Props {
  text: string
  useToc?: boolean
}

export const H2 = ({ text, useToc }: Props) => {
  const slug = slugify(text)
  const anchorRefForScroll = useRef<HTMLDivElement>(null)
  const anchorRefForToc = useRef<HTMLDivElement>(null)

  useScrollTo({ currentBlockId: slug, anchorRef: anchorRefForScroll })
  useActiveToc({ anchorRef: anchorRefForToc, isActive: useToc, currentBlockId: slug })

  return (
    <Root>
      <Anchor anchorRef={anchorRefForToc} currentBlockId={slug} verticalOffset={-632} />
      <Anchor anchorRef={anchorRefForScroll} currentBlockId={slug} verticalOffset={32} />
      <Text>{text}</Text>
    </Root>
  )
}

const Root = styled.h2`
  position: relative;
`

const Text = styled.span``
