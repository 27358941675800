import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, fontLibreBaskerville, hoverMedia } from '@/lib/theme'
import { h2Styles, h3Styles, mainElementOffsets, textLinkStyles } from './element-styles'

export const Typography = styled.div<{ $isInverse?: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: ${fontLibreBaskerville};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regularTransparent)};
  max-width: calc(100vw - 48px);
    
    & :first-child {
        margin-top: 0;
    }

  & p:not(:first-child) {
    margin-top: 1em;
  }

  & span {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  & i {
    font-style: normal;
  }

  & a {
    font-weight: 500;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    max-width: 100%;
    display: inline-block;
    ${textLinkStyles};
  }

  & b {
    font-weight: 700;
  }

  & h2 {
    ${h2Styles};
    margin-top: var(--content-blocks-gap);
    margin-bottom: 16px;

    & + h3 {
      margin-top: 40px;
    }
  }

  & h3 {
    ${h3Styles};
    margin-top: var(--content-blocks-gap);
  }

  & h4 {
    font-size: 16px;
  }

  & ul {
    list-style-type: disc;
  }

  & blockquote {
    ${mainElementOffsets};
    padding: 8px 0 8px 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.01em;
    border-left: 1px solid ${colors.tertiary.lightest};

    & p {
      margin: unset;
    }
  }

  & ol,
  ul {
    margin-left: 36px;
    margin-bottom: 0;

    & li {
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 1em;
      }

      & li {
        &:first-of-type {
          margin: unset;
        }
      }

      & p {
        margin: unset;
      }
    }
  }

  & table {
    margin-top: var(--content-blocks-gap);
    font-family: ${fontLeagueSpartan};
    font-size: 16px;
    font-weight: 400;
    line-height: 113%;
    letter-spacing: 0.015em;
    max-width: 100%;
    align-self: stretch;
    overflow-x: scroll;
    display: flex;

    & tbody {
      min-width: 100%;
    }

    & p {
      margin: 0;
    }

    & th,
    td {
      text-align: start;
      padding: 16px 32px;
    }

    & th {
      font-weight: 500;
      background-color: ${colors.secondary.lightest};
      border: 1px solid ${colors.white};
      color: white;
    }

    & td {
      border: 1px solid ${colors.white};
    }

    tr:nth-child(odd) {
      background-color: ${colors.background.grayLightest};
    }
  }
`
