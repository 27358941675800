import React from 'react'
import { styled } from '@linaria/react'
import { hoverMedia } from '@/lib/theme'
import { IconSocial } from '@/UI'

interface Props {
  url: string
  title: string
}

export const ShareIcons = ({ url, title }: Props) => {
  return (
    <Container>
      <Icon type="Twitter" urlAdd={`intent/tweet?url=${url}&text=${title}`} />
      <Icon type="Facebook" urlAdd={`sharer/sharer.php?u=${url}`} />
      <Icon type="Mail" urlAdd={`subject=${title}&body=${title} (${url})`} />
      <Icon type="LinkedIn" urlAdd={`sharing/share-offsite/?url=${url}`} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  cursor: pointer;
`

const Icon = styled(IconSocial)`
  ${hoverMedia} {
    transition: 0.2s ease-in-out;
    transition-property: opacity, filter;
    
    &:hover {
      filter: grayscale(1);
      opacity: 0.6;
    }
  }
`