/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, hoverMedia } from '@/lib/theme'
import { ShareModal, showModal } from '@/blocks/share-modal'

const Share = () => (
  <path d="m12 1-4 4h3v9h2v-9h3m2 18h-12c-.53043 0-1.03914-.2107-1.41421-.5858-.37508-.3751-.58579-.8838-.58579-1.4142v-12c0-.53043.21071-1.03914.58579-1.41421.37507-.37508.88378-.58579 1.41421-.58579h3v2h-3v12h12v-12h-3v-2h3c.5304 0 1.0391.21071 1.4142.58579.3751.37507.5858.88378.5858 1.41421v12c0 .5304-.2107 1.0391-.5858 1.4142s-.8838.5858-1.4142.5858z" />
)
const Print = () => (
  <path d="m18 10h-1v-6h-10v6h-1c-.53043 0-1.03914.2107-1.41421.5858-.37508.3751-.58579.8838-.58579 1.4142v7h16v-7c0-.5304-.2107-1.0391-.5858-1.4142s-.8838-.5858-1.4142-.5858zm-9-4h6v4h-6zm9 11h-12v-5h12zm-1-2h-4v-2h4z" />
)

interface ShareProps {
  type: 'share'
  slug: string
  title: string
}

interface PrintProps {
  type: 'print'
}

type Props = PrintProps | ShareProps

export const Control = (props: Props) => {
  const { type } = props

  const printHandler = () => {
    if (typeof window !== 'undefined') window.print()
  }

  const shareHandler = () => {
    if (type === 'share') showModal(props.slug)
  }

  return (
    <Root>
      <Element onClick={type === 'share' ? shareHandler : printHandler}>
        <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24">
          {type === 'share' ? <Share /> : <Print />}
        </svg>
      </Element>
      <Text>{type}</Text>
      {type === 'share' && (
        <ShareModal modalId={props.slug} text="Share this article" entityTitle={props.title} entityPath={props.slug} />
      )}
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  align-items: center;
  grid-row-gap: 8px;
`

const Element = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.primary.lightest};
  color: ${colors.secondary.secondary};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  ${hoverMedia} {
    &:hover {
      color: ${colors.white};
      background-color: ${colors.primary.primary};
    }
  }

  &:active {
    color: ${colors.white};
    background-color: ${colors.primary.darkest};
  }
`

const Text = styled.div`
  font-family: ${fontLeagueSpartan};
  font-size: 16px;
  font-weight: 700;
  line-height: 113%; /* 18.08px */
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-align: center;
`
