import React from 'react'
import { styled } from '@linaria/react'
import { graphql, PageProps } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { SEO, Layout } from '@/blocks/layout'
import {
  Highlights,
  OurClients,
  Factoids,
  Cases,
  OurPartners,
  ArticlesAnnouncements,
  LastVideos,
} from '~/features/home-page'
import { schemaOrgOrganization } from '@/utils/schema.org'
import { ContactFormWithWrapper } from '@/blocks/contact-form'
import { Break } from '@/UI'

const LandingPage = () => {
  return (
    <Layout>
      <Main>
        <Highlights />
        <Factoids />
        <Cases />
        <OurClients />
        <ContactFormWithWrapper title="Let’s begin our conversation:" messageHint="Your goals and challenges" />
        <Break $indent={72} />
        <ArticlesAnnouncements />
        <LastVideos />
        <OurPartners />
      </Main>
    </Layout>
  )
}

export default LandingPage

export const Head = ({ data }: PageProps<Queries.LandingPageQueryQuery>) => {
  const site = data.site.siteMetadata
  const pageTitle = site.title
  const pageDescription = site.description
  const seoImage = getImage(data.seoImage.image.fixed).images.fallback.src

  return (
    <SEO title={pageTitle} description={pageDescription} image={seoImage} richresult={schemaOrgOrganization(site)} />
  )
}

export const pageQuery = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    seoImage: contentfulDefaultImage(for: { eq: "SEO" }) {
      image {
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 10)
      }
    }
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
`
