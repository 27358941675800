import React, { ReactNode } from 'react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import 'mac-scrollbar/dist/mac-scrollbar.css'
import { MacScrollbar } from 'mac-scrollbar'
import { Wrapper, PortalWrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ButtonContactCommon } from './ButtonContactCommon'

interface Props {
  children: ReactNode[]
  closeHandler: () => void
  isVisible: boolean
}

export const PortalWrapperHeaderContent = ({ children, isVisible, closeHandler }: Props) => {
  return (
    <PortalWrapper closeHandler={closeHandler} isVisible={isVisible} initialHeight="var(--header-h)">
      <WrapperStyled $noMobilePadding={false}>
        <TopBlock>{children[0]}</TopBlock>
        <MacScrollbar className={contentInner} skin="dark">
          {children[1]}
        </MacScrollbar>
        <ContactBtnMobile buttonType="intoMenu" />
      </WrapperStyled>
    </PortalWrapper>
  )
}

const contentInner = css`
  ${desktopSmallMedia} {
    margin: 0 135px;
  }
`

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-h);
  flex-direction: row-reverse;
  flex-shrink: 0;

  ${desktopSmallMedia} {
    flex-direction: row;
  }
`

const ContactBtnMobile = styled(ButtonContactCommon)`
  display: flex;
  flex-shrink: 0;
  margin-top: auto;

  ${desktopSmallMedia} {
    display: none;
  }
`

const WrapperStyled = styled(Wrapper)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary.secondary};
  padding-bottom: 32px;
  max-height: 100%;
`
