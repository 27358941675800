import React, { ChangeEvent, useCallback } from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, modernMobileMedia } from '@/lib/theme'
import { formatTime } from '../formatTime'

interface Props {
  isOnMain?: boolean
  isPlaying: boolean
  currentTime: number
  duration: number
  audioRef: React.RefObject<HTMLAudioElement>
}

export const ProgressBar = ({ audioRef, isPlaying, duration = 0, currentTime = 0, isOnMain = false }: Props) => {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!audioRef?.current) return
      // eslint-disable-next-line no-param-reassign
      audioRef.current.currentTime = parseFloat(e.target.value)
    },
    [audioRef],
  )

  return (
    <Container>
      <StyledRangeInput
        isOnMain={isOnMain}
        type="range"
        min={0}
        max={duration}
        value={currentTime}
        onChange={changeHandler}
        current={currentTime}
        overall={duration}
        isPlaying={isPlaying}
      />
      <TimeBlock isOnMain={isOnMain} isPlaying={isPlaying}>
        <span>{formatTime(currentTime)}</span>
        <span>/</span>
        <span>{formatTime(duration)}</span>
      </TimeBlock>
    </Container>
  )
}

const TimeBlock = styled.div<{ isOnMain: boolean; isPlaying: boolean }>`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 92px;
  font-size: 14px;
  color: ${colors.tertiary.tertiary};

  ${desktopSmallMedia} {
    font-size: 16px;
    margin-left: 24px;
    width: 110px;
  }

  & span {
    transition: color 0.3s ease-in-out;
  }

  & span:first-child {
    color: ${({ isPlaying }) => (isPlaying ? colors.primary.primary : colors.tertiary.tertiary)};
  }

  & span:last-child {
    color: ${({ isOnMain }) => (isOnMain ? colors.white : colors.secondary.secondary)};
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0 6px 0 6px;
  
  ${modernMobileMedia} {
    margin: 0 16px 0 8px;
  }

  ${desktopSmallMedia} {
    margin: 0;
  }
`

const StyledRangeInput = styled.input<{ current: number; overall: number; isOnMain: boolean; isPlaying: boolean }>`
  display: none;
  width: 100%;
  height: 10px;
  appearance: none;
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;

  ${desktopSmallMedia} {
    display: flex;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: ${colors.tertiary.tertiary};
    border: none;
    border-radius: 0 5px 5px 0;
  }

  &::before {
    content: '';
    width: ${({ current, overall }) => {
      if (!overall || overall === 0) return 0
      return (current / overall) * 100
    }}%;
    height: 8px;
    top: 1px;
    transition: background-color 0.3s ease-in-out;
    background-color: ${({ isPlaying }) => (isPlaying ? colors.primary.primary : colors.white)};
    position: absolute;
    border-radius: 0 5px 5px 0;
  }

  &::-webkit-slider-thumb {
    visibility: hidden;
  }
`
