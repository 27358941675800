import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { TextLink, Wrapper, Title, Break, Divider } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface SectionProps {
  children: ReactNode
  title?: string
  linkUrl?: string
  linkText?: string
  hasDivider?: boolean
  noBackground?: boolean
  isNotOnHome?: boolean
}

export const AnnouncesSection = ({
  children,
  title,
  linkUrl,
  hasDivider,
  noBackground,
  isNotOnHome,
  linkText = 'Show more',
}: SectionProps) => {
  return (
    <Root $noBackground={noBackground}>
      {hasDivider ? (
        <Wrapper $noMobilePadding={false}>
          <Divider $customMargin={64} $customMarginMob={48} />
        </Wrapper>
      ) : (
        <Break $indent={64} $indentMobile={48} />
      )}
      {title && (
        <Wrapper $noMobilePadding={false}>
          <Title $gap={48} $gapMob={32} $isSlim={isNotOnHome} $customSizeMobile={isNotOnHome && 24}>
            {title}
          </Title>
        </Wrapper>
      )}
      <Inner $noMobilePadding={false} $noUltraThinPadding>
        {children}
        {linkUrl && (
          <LinkWrapper>
            <Link to={linkUrl}>
              <TextLink $size={isNotOnHome ? 'medium' : 'big'}>{linkText}</TextLink>
            </Link>
          </LinkWrapper>
        )}
      </Inner>
    </Root>
  )
}

const Root = styled.div<{ $noBackground: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ $noBackground }) =>
    $noBackground ? colors.white : colors.background.grayLightest};
  padding: 0 0 48px 0;

  ${desktopSmallMedia} {
    padding: 0 0 64px 0;
  }
`

const Inner = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`
const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${desktopSmallMedia} {
    justify-content: flex-end;
    margin-top: 48px;
  }
`
