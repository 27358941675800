import React, { ReactNode, useEffect, useMemo } from 'react'
import { useTransition, type Stage } from 'transition-hook'
import { styled } from '@linaria/react'
import { Portal } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { useEscKeyHandler, useMediaQuery } from '@/hooks'
import { use100vh } from 'react-div-100vh'

interface Props {
  children: ReactNode | ReactNode[]
  closeHandler: () => void
  isVisible: boolean
  offsetTop?: string
  initialHeight?: string
  fillColor?: string
}

export const PortalWrapper = ({
  children,
  isVisible,
  closeHandler,
  offsetTop = '0',
  initialHeight = '0',
  fillColor = colors.secondary.secondary,
}: Props) => {
  const isDesktop = useMediaQuery()
  const { stage, shouldMount } = useTransition(isVisible, isDesktop ? 100 : 200)
  useEscKeyHandler(closeHandler)

  const viewportHeight = use100vh()
  const portalHeight = `${viewportHeight - parseInt(offsetTop, 10)}px`

  useEffect(() => {
    if (!isVisible) return

    document.body.style.overflow = 'hidden'

    // eslint-disable-next-line consistent-return
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.removeProperty('overflow')
        if (!document.body.style[0]) document.body.removeAttribute('style')
      }
    }
  }, [isVisible])

  return (
    shouldMount && (
      <Portal zIndex={100000}>
        <PortalInner
          $stage={stage}
          onClick={closeHandler}
          $offsetTop={offsetTop}
          $initialHeight={initialHeight}
          $portalHeight={portalHeight}
          $fillColor={fillColor}
        >
          {children}
        </PortalInner>
      </Portal>
    )
  )
}

const PortalInner = styled.div<{ $stage: Stage; $offsetTop: string; $portalHeight: string; $initialHeight: string; $fillColor }>`
  top: ${({ $offsetTop }) => $offsetTop};
  height: ${({ $portalHeight }) => $portalHeight};
  display: flex;
  flex-direction: column;
  position: fixed;
  cursor: pointer;
  left: 0;
  width: 100vw;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s, 0.15s;
  transition-property: max-height, opacity;
  max-height: ${({ $stage, $portalHeight, $initialHeight }) => ($stage === 'enter' ? $portalHeight : $initialHeight)};
  opacity: ${({ $stage }) => ($stage === 'enter' ? 1 : 0)};
  background-color: ${({ $fillColor }) => $fillColor};

  ${desktopSmallMedia} {
    padding-bottom: 56px;
    transition-duration: 0.1s, 0.05s;
  }
`
