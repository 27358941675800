import React from 'react'
import { styled } from '@linaria/react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BLOCKS } from '@contentful/rich-text-types'
import { Script } from 'gatsby'
import { schemaOrgVideo } from '@/utils/schema.org'
import { LazyIframe } from './LazyIframe'
import { ReferencesType } from './types'
import { H2 } from './options'
import { mainElementOffsets } from '@/UI'

export function renderOptions(references: ReferencesType, useToc?: boolean) {
  if (!Array.isArray(references)) return null

  return {
    blockquote: node => {
      return <blockquote>{node.content}</blockquote>
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (content: any, text: object) => {
        return <H2 text={convertContentToText(content)} useToc={useToc} />
      },
      [BLOCKS.HEADING_3]: (content: any, text: string) => {
        return (
          <h3>
            <span>{convertContentToText(content)}</span>
          </h3>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: embedded => {
        const { data } = embedded
        const imageID = data.target.sys.id

        const { gatsbyImageData, title } = references.find(({ contentful_id: id }) => id === imageID)

        const image = getImage(gatsbyImageData)

        return <GatsbyImageStyled loading="lazy" image={image} alt={title} title={title} />
      },
      [BLOCKS.EMBEDDED_ENTRY]: embedded => {
        const { data } = embedded
        const entryID = data.target.sys.id
        const entryData = references.find(({ contentful_id: id }) => id === entryID)

        switch (entryData.__typename) {
          case 'ContentfulYoutubeEmbed':
            if (!entryData.videoId) return null // break if there's an error in embed
            return (
              <>
                <Script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{ __html: schemaOrgVideo(entryData.videoId) }}
                />
                <LazyIframe
                  title={entryData.videoId.title}
                  url={`https://www.youtube.com/embed/${entryData.videoId.videoId}?modestbranding=1&cc_load_policy=1&rel=0`}
                />
              </>
            )
          default:
            return null
        }
      },
    },
  }
}

const GatsbyImageStyled = styled(GatsbyImage)`
  ${mainElementOffsets};
`

function convertContentToText(content: any) {
  const contentString = documentToPlainTextString(content)

  return contentString.replaceAll('\u00A0', ' ')
}
