export const colors = {
  primary: {
    lightest: '#FFE1D3',
    light: '#FF9E7A',
    primary: '#FF7E4F',
    dark: '#E56A45',
    darkest: '#B15238',
  },
  secondary: {
    lightest: '#525050',
    secondary: '#272424',
    darkest: '#0E0D0E',
  },
  tertiary: {
    lightest: '#C3BDB6',
    tertiary: '#9E958F',
    darkest: '#7A726A',
  },
  outcome: {
    positive: {
      lightest: '#C4ECD0',
      positive: '#30C26A',
      darkest: '#279260',
    },
    negative: {
      lightest: '#FEDBD4',
      negative: '#E96C6C',
      darkest: '#D62B2B',
    },
  },
  background: {
    grayLightest: '#EBEAE8',
    gray: '#9E958F',
  },
  textStyles: {
    regular: '#272424',
    inverse: '#fff',
    regularTransparent: 'rgba(39, 36, 36, 0.80)',
    inverseTransparent: 'rgba(255, 255, 255, 0.70)',
  },
  white: '#fff',
} as const

export const mediaQuery = {
  sm: 0,
  modernMobile: 376,
  tablet: 640,
  desktopSmall: 1024,
  desktopMedium: 1280,
  desktop: 1440,
} as const

export const mqModernMobile = `(min-width: ${mediaQuery.modernMobile}px)`
export const mqTabletMedia = `(min-width: ${mediaQuery.tablet}px)`
export const mqDesktopSmall = `(min-width: ${mediaQuery.desktopSmall}px)`
export const mqDesktopMedium = `(min-width: ${mediaQuery.desktopMedium}px)`
export const mqDesktopLarge = `(min-width: ${mediaQuery.desktop}px)`

export const modernMobileMedia = `@media screen and ${mqModernMobile}`
export const tabletMedia = `@media screen and ${mqTabletMedia}`
export const desktopSmallMedia = `@media screen and ${mqDesktopSmall}`
export const desktopMediumMedia = `@media screen and ${mqDesktopMedium}`
export const desktopLargeMedia = `@media screen and ${mqDesktopLarge}`

export const notHoverMedia = '@media (hover: none)'
export const hoverMedia = '@media (hover: hover)'

export const printMedia = '@media print'

export const shadowStyle = '0 0 8px 2px rgba(0, 0, 0, 0.25)'
export const transition = 'box-shadow 0.3s ease-in-out'

export const fontInfer = `'Inter', sans-serif`
export const fontLibreBaskerville = `'Libre Baskerville', serif`
export const fontLeagueSpartan = `'League Spartan', sans-serif`
