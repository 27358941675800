import { RefObject, useEffect, useState } from 'react'

export function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    isActive = false,
  // eslint-disable-next-line no-undef
  }: IntersectionObserverInit & { isActive?: boolean },
): boolean {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const updateEntry = ([currentEntry]: IntersectionObserverEntry[]): void => {
    setEntry(currentEntry)
  }

  useEffect(() => {
    if (!isActive) return

    const node = elementRef?.current
    const hasIOSupport = Boolean(window.IntersectionObserver)

    if (!hasIOSupport || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin])

  return Boolean(entry?.isIntersecting)
}
