import React from 'react'
import { styled } from '@linaria/react'

export const ExternalLink = ({ size = 16 }: { size?: number }) => {
  return (
    <Container size={size}>
      <svg width={size} height={size} viewBox="0 0 20 20">
        <path
          d="M17 13.5v6H5v-12h6m3-3h6v6m0-6-9 9"
          stroke="currentColor"
          strokeWidth="1.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  )
}

const Container = styled.span<{ size: number }>`
  display: flex;
  margin-left: 4px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  transform: translateY(${({ size }) => (-1 * size) / 10}px);
`
