import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { tagState } from '~/features/insights'
import { Post } from '../types'
import { desktopSmallMedia, fontLeagueSpartan, printMedia } from '@/lib/theme'
import { textLinkStyles } from '@/UI'

interface Props extends Pick<Post, 'tags' | 'title'> {}

export const TitleBlock = ({ tags, title }: Props) => {
  const tag = Array.isArray(tags) && tags.length > 0 ? tags[0] : null

  const tagClickHandler = () => {
    tagState.selectedTag = tag.tag
  }

  return (
    <Root>
      {tag && (
        <Tag to="/blog" onClick={tagClickHandler}>
          {tag.tag}
        </Tag>
      )}
      <Title>{title}</Title>
    </Root>
  )
}

const Root = styled.section`
  font-family: ${fontLeagueSpartan};
  display: grid;
  grid-area: title;
  grid-row-gap: 24px;
  margin-top: 32px;
  margin-bottom: var(--content-blocks-gap);

  ${desktopSmallMedia} {
    margin: 64px 0 56px 0;
  }
`

const Tag = styled(Link)`
  ${textLinkStyles};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.004em;

`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01em;

  ${desktopSmallMedia} {
    font-size: 76px;
    font-weight: 500;
    line-height: 105%;
    letter-spacing: -0.015em;
  }
`
