import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { colors, desktopSmallMedia, fontLeagueSpartan, hoverMedia } from '@/lib/theme'
import { ArticleAuthors, AspectRatioContainer, AuthorType, Typography } from '@/UI'
import type { RichTextContentType } from '@/blocks/rich-text-improoved'

interface Props {
  isMain?: boolean
  data: Queries.homepageArticlesAnnounceQuery['allContentfulPost']['edges'][number]['node']
}

export const ArticleAnnounce = ({ data, isMain }: Props) => {
  const { title, authors, slug, tags, excerpt, image } = data

  const tagsFinal = Array.isArray(tags) && tags[0].tag
  const titleFinal = title ?? slug
  const textFinal = excerpt?.childMarkdownRemark?.html as unknown as RichTextContentType
  const authorsFinal = authors?.map(
    ({ name, miniavatar }) => ({ name, img: miniavatar.gatsbyImageData } as unknown as AuthorType),
  )

  const postImage = image?.fluid as unknown as IGatsbyImageData

  return (
    <Link to={`/${slug}/`}>
      <Root $isMain={isMain}>
        <ImageBlock verticalRatio="60%" $isMain={isMain}>
          <GatsbyImage alt={titleFinal} image={postImage} />
        </ImageBlock>
        <Content $isMain={isMain}>
          <Tags>{tagsFinal}</Tags>
          <Title $isMain={isMain}>{titleFinal}</Title>
          <TextStyled $isInverse={isMain} dangerouslySetInnerHTML={{ __html: textFinal }} />
          <ArticleAuthorsStyled authors={authorsFinal} />
        </Content>
      </Root>
    </Link>
  )
}

interface CommonStylesProps {
  $isMain: boolean
}

const Root = styled.div<CommonStylesProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ $isMain }) => ($isMain ? 'transparent' : colors.white)};
  padding: ${({ $isMain }) => ($isMain ? 0 : '16px')};
  border-radius: ${({ $isMain }) => ($isMain ? 0 : '16px')};
  max-width: 480px;
  height: 100%;
  align-self: center;
  transition: box-shadow 0.2s ease-in-out;

  ${desktopSmallMedia} {
    padding: ${({ $isMain }) => ($isMain ? 0 : '24px')};
    max-width: unset;
    align-self: unset;
    flex-direction: ${({ $isMain }) => ($isMain ? 'row' : 'column')};
  }

  ${hoverMedia} {
    &:hover {
      box-shadow: 0 0 8px 2px rgba(39, 36, 36, 0.3);
    }
  }
`

const Content = styled.div<CommonStylesProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ $isMain }) => ($isMain ? 0 : 1)};

  ${desktopSmallMedia} {
    align-self: ${({ $isMain }) => ($isMain ? 'center' : 'unset')};
  }
`

const ImageBlock = styled(AspectRatioContainer)<CommonStylesProps>`
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;

  ${desktopSmallMedia} {
    margin-bottom: ${({ $isMain }) => ($isMain ? 0 : 24)}px;
    margin-right: ${({ $isMain }) => ($isMain ? 24 : 0)}px;
    width: ${({ $isMain }) => ($isMain ? 43.5 : 100)}%;
  }
`

const ArticleAuthorsStyled = styled(ArticleAuthors)`
  margin-top: auto;
`

const Tags = styled.div`
  color: ${colors.tertiary.tertiary};
  font-family: ${fontLeagueSpartan};
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 16px;
`

const Title = styled.h3<CommonStylesProps>`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 12px;
  color: ${({ $isMain }) => ($isMain ? colors.textStyles.inverse : colors.textStyles.regular)};

  ${desktopSmallMedia} {
    font-size: ${({ $isMain }) => ($isMain ? 36 : 24)}px;
  }
`

const TextStyled = styled(Typography)`
  margin-bottom: 24px;
`
