import React from 'react'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { Wrapper } from '@/UI'

import {
  Logo,
  MenuSearchDesktop,
  MenuSearchMobile,
  Search,
  MenuBurger,
  ButtonContactMob,
  ButtonContactCommon,
  NavDesktop,
  MenuMobile,
} from './components'
import { topBlockShadow } from '@/blocks/layout/partials/common'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

interface Props {
  withShadow?: boolean
}

export const Header = ({ withShadow }: Props) => {
  return (
    <Container $withShadow={withShadow}>
      <ContentWrapper $noMobilePadding={false}>
        <MenuBurger />
        <Logo />
        <MenuSearchDesktop />
        <NavDesktop />
        <MenuSearchMobile />
        <ButtonContactMob />
        <ButtonContactCommon buttonType="onHeader" />
      </ContentWrapper>
      <Search />
      <MenuMobile />
    </Container>
  )
}

const Container = styled.header<{ $withShadow: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${colors.secondary.secondary};
  position: fixed;
  top: 0;
  z-index: 99;
  height: var(--header-h);
  box-shadow: ${({ $withShadow }) => $withShadow ? topBlockShadow : 'none'};
  ${doNotPrintThisBlock};
`

const ContentWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`
