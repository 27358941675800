import React, { useRef } from 'react'
import { styled } from '@linaria/react'
import { useIntersectionObserver } from '@/hooks'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import { mainElementOffsets } from '@/UI'

export function LazyIframe({ url, title }: { url: string; title: string }) {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const isIntersecting = useIntersectionObserver(containerRef, { isActive: true })
  if (isIntersecting) {
    lockRef.current = true
  }
  return (
    <EmbeddedVideo ref={containerRef}>
      {lockRef.current && (
        <iframe
          title={title}
          src={url}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </EmbeddedVideo>
  )
}

const EmbeddedVideo = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 */
  overflow: hidden;
  width: 100%;
  ${mainElementOffsets};
  ${doNotPrintThisBlock};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
