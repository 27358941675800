import React from 'react'
import { styled } from '@linaria/react'
import { desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { TableOfContents } from './TocContent'
import { Post } from '../../types'

interface Props extends Pick<Post, 'content'> {}

export const TocDesktop = ({ content }: Props) => {
  return (
    <TocDesktopView>
      <Title>Table of contents</Title>
      <TableOfContentsStyled content={content} />
    </TocDesktopView>
  )
}

const TocDesktopView = styled.section`
  grid-area: toc;
  flex-direction: column;
  position: sticky;
  top: var(--all-top-blocks-h);
  align-self: start;
  width: 100%;
  padding: 24px 0;
  display: none;

  ${desktopSmallMedia} {
    display: flex;
  }
`

const Title = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.24px;
  margin-bottom: 24px;
`

const TableOfContentsStyled = styled(TableOfContents)`
  max-height: calc(100vh - var(--all-top-blocks-h) - 24px - 24px - 48px);
  flex-grow: 1;
`