import React from 'react'
import { styled } from '@linaria/react'
import { fontLeagueSpartan } from '@/lib/theme'
import { type RichTextContentType, RichTextImproved } from '@/blocks/rich-text-improoved'

interface Props {
  title: string
  text: RichTextContentType
}

export const Block = ({ title, text }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      <RichTextImproved content={text} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h3`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 12px;
`
