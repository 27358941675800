import React, { useEffect } from 'react'
import { styled } from '@linaria/react'
import { Styleable } from '@/lib/interfaces'
import { customFormParams } from './formState'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'

import { InputsGroup, NewsletterConsent, PrivacyAgreement, Submit } from './components'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

interface Props extends Styleable {
  messageHint?: string
  appearance?: AppearanceType
}

export const ContactForm = ({ className, messageHint, appearance }: Props) => {
  useEffect(() => {
    customFormParams.messageHint = messageHint
  }, [messageHint])

  return (
    <Container className={className}>
      <InputsGroup appearance={appearance} />
      <AgreementGroup>
        <PrivacyAgreement appearance={appearance} />
        <NewsletterConsent appearance={appearance} />
      </AgreementGroup>
      <Submit />
    </Container>
  )
}

const Container = styled.form`
  display: grid;
  grid-row-gap: 32px;
  ${doNotPrintThisBlock};
`

const AgreementGroup = styled.div`
  display: grid;
  grid-row-gap: 16px;
`
