import React from 'react'
import { styled } from '@linaria/react'
import { Control } from './Control'
import { Styleable } from '@/lib/interfaces'

interface Props extends Styleable {
  slug: string
  title: string
}

export const OutputControls = ({ slug, title, className }: Props) => {
  return (
    <Root className={className}>
      <Inner>
        <Control type="print" />
        <Control type="share" slug={slug} title={title} />
      </Inner>
    </Root>
  )
}

const Root = styled.div``

const Inner = styled.div`
  display: grid;
  grid-template-columns: 48px 48px;
  grid-gap: 24px;
`
