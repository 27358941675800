import React from 'react'
import { styled } from '@linaria/react'
import { WithChildrenOptional } from '@/lib/interfaces'
import { Breadcrumbs } from './Breadcrumbs'
import { desktopLargeMedia, desktopSmallMedia, tabletMedia } from '@/lib/theme'
import { calculateSubheaderItemsVisibility } from './calculateSubheaderItemsVisibility'
import { MediaShowSubheader } from './types'
import { topBlockShadow } from '@/blocks/layout/partials/common'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

export interface SubheaderProps {
  breadcrumbsText?: string
  breadcrumbsLink?: string
  bpToShowSubheaderItems?: MediaShowSubheader[]
}

export const SubHeader = ({
  children,
  breadcrumbsText,
  breadcrumbsLink,
  bpToShowSubheaderItems,
}: SubheaderProps & WithChildrenOptional) => {
  return (
    <Root>
      {breadcrumbsText && <Breadcrumbs text={breadcrumbsText} parentNodePath={breadcrumbsLink} />}
      {children && <ChildrenWrapper $bpToShowSubheaderItems={bpToShowSubheaderItems}>{children}</ChildrenWrapper>}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: var(--header-h);
  width: 100vw;
  z-index: 98;
  box-shadow: ${topBlockShadow};
  ${doNotPrintThisBlock};
`
const ChildrenWrapper = styled.div<{ $bpToShowSubheaderItems: MediaShowSubheader[] | undefined }>`
  display: ${({ $bpToShowSubheaderItems }) =>
    calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'mobile') ? 'block' : 'none'};

  ${tabletMedia} {
    display: ${({ $bpToShowSubheaderItems }) =>
      calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'tablet') ? 'block' : 'none'};
  }

  ${desktopSmallMedia} {
    display: ${({ $bpToShowSubheaderItems }) =>
      calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopSmall') ? 'block' : 'none'};
  }

  ${desktopLargeMedia} {
    display: ${({ $bpToShowSubheaderItems }) =>
      calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopLarge') ? 'block' : 'none'};
  }
`
