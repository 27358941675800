import React from 'react'
import { styled } from '@linaria/react'
import Pluralize from 'react-pluralize'
import { IconSocial } from '@/UI'
import theme from '~/gatsby-plugin-theme-ui'
import { colors } from '@/lib/theme'

interface Props {
  videosCount: number
}

export const TopInfo = ({ videosCount }: Props) => {
  return (
    <>
      <Text>
        <p>
          Showing <Pluralize singular="selected video" count={videosCount} />. We have many more on the channel.
        </p>
        <p>Visit and subscribe here:</p>
      </Text>
      <LinkToOrigin target="_blank" href="https://youtube.com/samexperttv">
        <IconSocial type="Youtube" urlAdd={''} size={20} fillBg={colors.white} fillContent={colors.primary.primary} />
        <YoutubeText>YouTube</YoutubeText>
      </LinkToOrigin>
    </>
  )
}

const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  letter-spacing: -0.02em;
`

const YoutubeText = styled.span`
  margin-left: 8px;
  
`

const LinkToOrigin = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: ${theme.colors.secondary};
  transition: background-color 0.3s ease-in-out;
  color: #fff;
  cursor: pointer;
  user-select: none;
  justify-self: start;

  &:hover {
    background-color: ${theme.colors.primary};
  }
`
