import React from 'react'
import type { Document } from '@contentful/rich-text-types'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { reWrap } from '@/utils/reWrap'
import type { RichTextContentType } from './types'
import { renderOptions } from './RenderOptions'
import { Typography } from '@/UI'

interface Props {
  content: RichTextContentType
  className?: string
  isInverse?: boolean
  parseReferences?: boolean
  useToc?: boolean
}

export const RichTextImproved = ({ content, className, isInverse, parseReferences, useToc }: Props) => {
  if (!content) return null

  const options = parseReferences && content.references ? renderOptions(content.references, useToc) : undefined

  return (
    <Typography className={className} as="section" $isInverse={isInverse}>
      {documentToReactComponents(reWrap(content) as Document, options as Options)}
    </Typography>
  )
}
