import { CSSProperties } from '@linaria/core'
import { colors, fontLeagueSpartan, hoverMedia } from '@/lib/theme'

export const hBase: CSSProperties = {
  fontFamily: fontLeagueSpartan,
  fontWeight: 700,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}

export const h2Styles: CSSProperties = {
  ...hBase,
  fontSize: 36,
}

export const h3Styles: CSSProperties = {
  ...hBase,
  fontSize: 24,
}

export const textLinkStyles: CSSProperties = {
  color: colors.secondary.darkest,
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: '0.2s',
  transitionProperty: 'color',
  textDecoration: 'underline',

  [hoverMedia]: {
    '&:hover': {
      color: colors.primary.primary,
    },
  },
  '&:active': {
    textDecoration: 'none',
    color: colors.primary.darkest,
  },
}

export const mainElementOffsets: CSSProperties = {
  margin: 'var(--content-blocks-gap) 0',

  '& + *': {
    marginTop: '0 !important',
  },
}
