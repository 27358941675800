import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { Link } from 'gatsby'
import { IconSocial } from '@/UI'
import { colors } from '@/lib/theme'

export const FooterSocial = () => {
  return (
    <Container>
      <Link to="/" title="SAMexpert homepage" className={logoStyles}>
        <img src="/SAMexpert-W.min.svg" alt="SAMexpert logo" width={150} height={44.29} />
      </Link>
      <SocialGrid>
        <IconSocial
          size={28}
          type="Youtube"
          urlAdd="samexperttv"
          fillBg="currentColor"
          fillContent={colors.secondary.secondary}
        />
        <IconSocial
          size={28}
          type="LinkedIn"
          urlAdd="company/samexpert"
          fillBg="currentColor"
          fillContent={colors.secondary.secondary}
        />
        <IconSocial
          size={28}
          type="Twitter"
          urlAdd="samexpert"
          fillBg="currentColor"
          fillContent={colors.secondary.secondary}
        />
        <IconSocial
          size={28}
          type="Telegram"
          urlAdd="samexperttv"
          fillBg="currentColor"
          fillContent={colors.secondary.secondary}
        />
      </SocialGrid>
    </Container>
  )
}

const Container = styled.div`
  grid-area: social;
  display: grid;
  justify-items: left;
  gap: 1em;
  grid-template: 'logo' 'icons';
`
const logoStyles = css`
  grid-area: logo;
  padding: 0;
  line-height: 0;
  height: 45px;
`

const SocialGrid = styled.div`
  grid-area: icons;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  display: grid;
  grid-column-gap: 12px;
  & a {
    font-size: 1em;
    align-self: center;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & svg {
    height: 28px;
    width: auto;
  }
`
