import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { h2Styles, TextLink } from '@/UI'
import { Post } from '../types'
import { fontLeagueSpartan } from '@/lib/theme'
import { tagState } from '~/features/insights'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

interface Props {
  tags: Post['tags']
}

const POSTS_TO_SHOW = 5

export const RelatedLinks = ({ tags }: Props) => {
  const tag = Array.isArray(tags) && tags.length > 0 ? tags[0] : null
  const posts = tag?.post?.length ? tag.post : null

  const postsFinal = posts.slice(0, POSTS_TO_SHOW)
  const hasShowMore = posts.length > POSTS_TO_SHOW

  return (
    <Root>
      <Title>Read next</Title>
      <Section>
        {postsFinal.map(({ id, slug, title }) => (
          <LinkStyled key={id} to={`/${slug}/`}>
            {title}
          </LinkStyled>
        ))}
      </Section>
      {hasShowMore && (
        <Link
          to="/blog/"
          onClick={() => {
            tagState.selectedTag = tag.tag
          }}
        >
          <TextLinkStyled $size="medium">More articles</TextLinkStyled>
        </Link>
      )}
    </Root>
  )
}

const Root = styled.section`
  grid-area: related;
  display: grid;
  grid-row-gap: 32px;
  ${doNotPrintThisBlock};
`

const Section = styled.nav`
  display: grid;
  grid-row-gap: 24px;
`
const Title = styled.h2`
  ${h2Styles};
`

const LinkStyled = styled(Link)`
  font-family: ${fontLeagueSpartan};
  font-size: 20px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: underline;
`
const TextLinkStyled = styled(TextLink)`
  justify-self: start;
`
